export const testimonials = [
  {
    quote:
      'Forming future leaders today is one of the most pressing tasks we now face, and no one in this country does it with more energy, depth, and skill than the Augustine Institute.',
    author: 'Former Archbishop Charles J. Chaput',
    position: 'OFM CAP',
    image: 'charles-chaput.jpg',
  },
  {
    quote:
      'This education and formation has changed my life. I am eternally grateful for what the Augustine Institute has done for my soul, my family and my apostolate. If you feel called to further your education and theological studies, I cannot recommend anything better than the Augustine Institute.',
    author: 'Leah Darrow',
    position: 'MA Theology ‘17',
    image: 'leah-darrow.jpg',
  },
  {
    quote:
      'I pray that my teaching and writing will be of service to the New Evangelization, helping equip my students to engage post-modernity and propose the timeless teachings of Christ in a timely way for our age.',
    author: 'Dr. Ted Sri ',
    position: 'Visiting Professor of Theology',
    image: 'ted-sri.jpg',
  },
  {
    quote:
      'I really cannot say enough good things about the education and human formation we received as students at the Augustine Institute. From the outstanding professors to the rich curriculum, everything lends itself to the development of the intellect and the zeal to bring Jesus Christ to the world. I would highly recommend the Augustine Institute to anyone looking to achieve excellence in knowledge, wisdom, and virtue.',
    author: 'Sr. Miriam James Heidland, SOLT',
    position: 'MA Theology ‘15',
    image: 'miriam-heidland.jpg',
  },
  {
    quote:
      'The Augustine Institute has long since cemented its place as a leader in forming Catholic educators and missionaries.  Firmly at the heart of the Church in its catechesis, the Augustine Institute continues to grow as a center for theological formation and as a hub for academic theological research, especially in patristics and Scripture. For scholars and students alike, the Augustine Institute is today recognized as standing among the very best institutions of faithful Catholic theological education.',
    author: 'Dr. Matthew Levering',
    position:
      'James N. and Mary D. Perry Jr. Chair of Theology, Mundelein Seminary',
    image: 'matthew-levering.jpg',
  },
];
