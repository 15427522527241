import React from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { testimonials } from '../data/testimonials';
import SEO from '../components/Seo';

interface ImageProps {
  name: string;
  ext: string;
  childImageSharp: ImageDataLike;
}
interface Props {
  data: {
    images: {
      nodes: Array<ImageProps>;
    };
  };
}

export default function TestimonialsPage({ data }: Props) {
  const images = data.images.nodes;
  return (
    <>
      <SEO
        title="What People are Saying"
        description="Archbishop Charles J. Chaput: Forming future leaders today is one of the most pressing tasks we now face, and no one in this country does it with more energy, depth, and skill than the Augustine Institute."
      />
      <main className="page-testimonials">
        <section className="container section-m-t page-m-b">
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              <h2>What People are Saying</h2>
              <div className="testimonials">
                <ul>
                  {testimonials.map((testimonial, i) => {
                    const image = images.find(
                      (img) => `${img.name}${img.ext}` === testimonial.image
                    );
                    const gatsbyImage = image
                      ? getImage(image.childImageSharp)
                      : null;
                    return (
                      <li key={`testimonial-${i}`} className="testimonial">
                        {gatsbyImage && (
                          <div className="testimonial__img">
                            <GatsbyImage
                              image={gatsbyImage}
                              alt={testimonial.author}
                            />
                          </div>
                        )}
                        <div className="testimonial__inner">
                          <blockquote>{testimonial.quote}</blockquote>
                          <div className="testimonial__author">
                            <p className="testimonial__author__name">
                              {testimonial.author}
                            </p>
                            <p className="testimonial__author__position h6">
                              {testimonial.position}
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export const query = graphql`
  query {
    images: allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
      nodes {
        name
        ext
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
